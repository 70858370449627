import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import AppWrapper from '../app/layouts/AppWrapper'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'
import BlogPostPage from '../ui/pages/BlogPostPage'
import { getLocaleFromPathname } from '../libs/i18n'
import { groupBlogrollBySection } from '../app/utils/blog'

const BlogPost = ({ data, ...props }) => {
  const {
    post,
    blogroll: { edges: blogroll },
  } = data
  const blogrollBySection = groupBlogrollBySection(blogroll)
  const locale = getLocaleFromPathname(props.location)
  return (
    <>
      <HelmetComponent title={metaTags.blogTitle} description={metaTags.blog} />
      <AppWrapper {...props} lang={locale}>
        <BlogPostPage post={post} blogroll={blogrollBySection} />
      </AppWrapper>
    </>
  )

  //                 (
  //   <BlogPostComponent
  //     id={post.id}
  //     content={post.html}
  //     helmet={
  //       <Helmet titleTemplate="%s | Blog">
  //         <title>{`${post.frontmatter.title}`}</title>
  //       </Helmet>
  //     }
  //     tags={post.frontmatter.tags}
  //     title={post.frontmatter.title}
  //   />
  // )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        path
        title
        date
        location
        image
        tags
      }
    }
    blogroll: allMarkdownRemark(
      sort: { fields: [frontmatter___date, frontmatter___title], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            path
            date
          }
        }
      }
    }
  }
`
