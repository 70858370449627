import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Disqus from 'disqus-react'
import { HTMLContent } from '../Content'
import useRevealEffect from '../../../hooks/effects/useRevealEffect'
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TumblrShareButton,
  TumblrIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share'

export const ShareButtons = ({ url, title }) => {
  const props = { url }
  const iconProps = { size: 32, round: false }
  return (
    <div className="share-buttons air-top">
      <EmailShareButton {...props}>
        <EmailIcon {...iconProps} />
      </EmailShareButton>
      <FacebookShareButton {...props} quote={title}>
        <FacebookIcon {...iconProps} />
      </FacebookShareButton>
      <LinkedinShareButton {...props} title={title} source="Aucta Blog">
        <LinkedinIcon {...iconProps} />
      </LinkedinShareButton>
      <RedditShareButton {...props} title={title}>
        <RedditIcon {...iconProps} />
      </RedditShareButton>
      <TumblrShareButton {...props} title={title}>
        <TumblrIcon {...iconProps} />
      </TumblrShareButton>
      <TwitterShareButton {...props} title={title} via="Aucta Blog">
        <TwitterIcon {...iconProps} />
      </TwitterShareButton>
      <WhatsappShareButton {...props} title={title}>
        <WhatsappIcon {...iconProps} />
      </WhatsappShareButton>
    </div>
  )
}

export const BlogPost = ({ post }) => {
  const {
    id,
    frontmatter: { title, date, location, image },
    html: content,
  } = post
  const disqusConfig = { identifier: id, title: title }
  const url = typeof window == 'undefined' ? '/' : window.location.href

  const panelRef = useRevealEffect()
  return (
    <section className="blog-section wrapper" ref={panelRef}>
      {/* page title */}
      {/* <Helmet titleTemplate="%s | Blog"> */}
      {/*   <title>{title}</title> */}
      {/* </Helmet> */}
      <div className="container blog-content blog-post">
        <div className="">
          <div className="post-wrapper">
            {/* main image (if present) */}
            {image && <img src={image} alt="post-main-imange" />}
            {/* post title */}
            <h1 className="blog-post-title">{title}</h1>
            {/* date and location (if present) */}
            <p className="meta-info">
              {location && `${location} - `} {moment(date).format('LL')}
            </p>
            {/* post content */}
            <HTMLContent content={content} />

            {/* share buttons */}
            <ShareButtons url={url} />
          </div>
          {/* disqus widget */}
          <Disqus.DiscussionEmbed
            shortname="dev-auctablog"
            config={disqusConfig}
          />
        </div>
      </div>
    </section>
  )
}

BlogPost.propTypes = {
  content: PropTypes.node,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

export default BlogPost
