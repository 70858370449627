import React from 'react'
import useTranslations from '../../../hooks/useTranslations'
import Input from '../form-elements/Input'
import Button from '../buttons/Button'
import Link from 'gatsby-link'

const SubscribeForm = ({ id }) => {
  const t = useTranslations()
  return (
    <form
      action="https://aucta.us7.list-manage.com/subscribe/post?u=2f681c9bf7cc64aa39acfa43b&amp;id=0c8be6943c"
      method="post"
      target="_blank"
      noValidate
      className="control-group-wrapper"
    >
      <div className="control-group">
        <Input
          name="email"
          type="email"
          placeholder="me@email.com"
          literal
          literalLabel
          hiddenLabel
          label="write here your email"
          id={id}
        />
        <Button type="submit" as="button" text="action:sign-up" />
      </div>
      <Link className="caption" to="/data-privacy">
        {t('noun:email-terms')}
      </Link>
    </form>
  )
}

export default SubscribeForm
