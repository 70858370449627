import moment from 'moment'

export function groupBlogrollBySection(blogroll) {
  return blogroll.reduce((acc, el) => {
    const section = el.node.frontmatter.path
    if (!acc[section]) acc[section] = []
    acc[section].push(el)
    return acc
  }, {})
}

export function formatPreviewNodes(data = [], nItems = 2) {
  const items = data.slice(0, nItems).map(({ node }) => ({
    href: node.fields.slug,
    alt: node.frontmatter.title,
    image: node.frontmatter.thumbnail,
    date: moment(node.frontmatter.date).format('LL'),
    summary: node.frontmatter.title,
  }))
  return items
}
