import React from 'react'
import BlogLayout from '../layouts/BlogLayout'
import BlogPostView from '../views/BlogPostView'

const BlogPostPage = ({ post, blogroll }) => {
  const activePath = post.frontmatter.path
  return (
    <BlogLayout blogroll={blogroll} activePath={activePath}>
      <BlogPostView post={post} />
    </BlogLayout>
  )
}

export default BlogPostPage
