import React from 'react'
import PropTypes from 'prop-types'
import BlogRoll from '../components/blog/BlogRoll'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import useSwitchLanguage from '../../hooks/useSwitchLanguage'
import { useIntl } from 'react-intl'
import { headerItems } from '../mock-data/header'
import footerItems from '../mock-data/footer'
import CtaPanel from '../components/panels/CtaPanel'

const BlogLayout = ({ children, blogroll, topSlot, activePath }) => {
  const { locale } = useIntl()
  const handleChangeLang = useSwitchLanguage(locale)
  return (
    <div className="outer-wrapper blog-wrapper">
      <Header
        items={headerItems}
        locale={locale}
        onChangeLang={handleChangeLang}
        blogroll={blogroll}
      />
      {topSlot ? <div className="top-slot">{topSlot}</div> : null}
      <div className="content">
        <BlogRoll blogroll={blogroll} activePath={activePath} />
        {children}
      </div>
      <div className="brick is-clear">
        <CtaPanel />
      </div>
      <Footer items={footerItems} />
    </div>
  )
}

BlogLayout.propTypes = {
  children: PropTypes.node,
}

export default BlogLayout
