import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useTranslations from '../../../hooks/useTranslations'

const FormField = ({
  isDisabled,
  textError,
  children,
  hasError,
  hiddenLabel,
  labelFor,
  literalLabel,
  label,
  isRequired,
}) => {
  const classes = cx('form-field', {
    'has-error': hasError,
    'is-disabled': isDisabled,
    'hidden-label': hiddenLabel,
  })
  const t = useTranslations()
  let labelText
  if (label && !literalLabel) {
    labelText = t(label)
  } else {
    labelText = label
  }
  if (isRequired) {
    labelText = `${labelText}*`
  }
  return (
    <div className={classes}>
      {labelText ? (
        <label htmlFor={labelFor} className="label">
          {labelText}
        </label>
      ) : null}
      {children}
      {hasError ? <span className="error-text">{t(textError)}</span> : null}
    </div>
  )
}

FormField.propTypes = {
  isDisabled: PropTypes.bool,
  textError: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  hasError: PropTypes.bool,
}

export default FormField
