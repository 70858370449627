import React, { useState, useCallback } from 'react'
import Link from 'gatsby-link'
import cx from 'classnames'
import chevronTop from '../../../assets/icons/icon-chevron-top.svg'

const BlogRollDropdown = ({ title, blogroll, category, startOpen = false }) => {
  const items = blogroll[category] || []
  const [isCollapsed, setCollapsed] = useState(!startOpen)
  const toggleOpen = useCallback(() => setCollapsed(!isCollapsed), [
    isCollapsed,
  ])
  return (
    <div className={cx('blog-roll-dropdown', { collapsed: isCollapsed })}>
      <Link
        className="blog-roll-dropdown-title"
        to={`/blog/${category}`}
        onClick={toggleOpen}
      >
        {title}
        <span className="blog-roll-dropdown-counter">{items.length}</span>
        <img
          className="icon-chevron"
          src={chevronTop}
          alt="chevron"
          width={15}
          height={9}
        />
      </Link>
      <div className="blog-roll-dropdown-links">
        {items.map(item => (
          <Link
            className="blog-roll-dropdown-link"
            key={item.node.id}
            to={item.node.fields.slug}
            activeClassName="active"
          >
            {item.node.frontmatter.title}
          </Link>
        ))}
      </div>
    </div>
  )
}

export default BlogRollDropdown
