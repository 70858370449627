import React, { useState, useCallback, useLayoutEffect, useEffect } from 'react'
import SubscribeForm from '../form-elements/SubscribeForm'
import Link from 'gatsby-link'
import cx from 'classnames'
import BlogRollDropdown from './BlogRollDropdown'
import chevronTop from '../../../assets/icons/icon-chevron-top.svg'
import blogCategories from '../../../config/blog.json'

const isCategoryVisible = category => category.visible

const BlogRoll = ({ blogroll, className, activePath }) => {
  const [isBlogMenuOpen, setisBlogMenuOpen] = useState(false)
  const toggleMenu = useCallback(() => setisBlogMenuOpen(!isBlogMenuOpen), [
    isBlogMenuOpen,
  ])

  useLayoutEffect(() => {
    // Remove body scroll when menu is open
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow
    // Prevent scrolling on mount
    if (isBlogMenuOpen) {
      document.body.style.overflow = 'hidden'
    }
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle)
  }, [isBlogMenuOpen]) // Empty array ensures effect is only run on mount and unmount

  const [y, setY] = useState(typeof window !== 'undefined'.scrollY)

  const handleNavigation = useCallback(
    e => {
      const window = e.currentTarget
      if (y > window.scrollY && window.scrollY > 60) {
        document.body.className = 'scroll-up'
      } else if (y < window.scrollY) {
        document.body.className = 'scroll-down'
      }
      setY(window.scrollY)
    },
    [y],
  )

  useEffect(() => {
    setY(window.scrollY)
    window.addEventListener('scroll', handleNavigation)

    return () => {
      window.removeEventListener('scroll', handleNavigation)
    }
  }, [handleNavigation])

  return (
    <div
      className={cx('blog-roll', {
        [className]: className,
      })}
    >
      <div className="blog-roll-sticky">
        <div className="blog-roll-header">
          <Link to="/blog" className="blog-roll-title">
            AUCTA Blog
          </Link>
          <button
            className="button-menu show-mobile"
            onClick={toggleMenu}
            aria-label="menu"
          >
            <span className="text">Menu</span>
            <img
              className={cx('icon-chevron', { expand: isBlogMenuOpen })}
              src={chevronTop}
              alt="chevron"
              width={15}
              height={9}
            />
          </button>
        </div>
        <div
          className={cx('blog-roll-content', {
            'open-blog-menu': isBlogMenuOpen,
          })}
        >
          <div className="blog-roll-section">
            <h2 className="blog-roll-section-title">Subscribe</h2>
            <SubscribeForm id="subscribe_email_blog" />
          </div>

          {blogCategories.filter(isCategoryVisible).map(category => (
            <BlogRollDropdown
              key={category.key}
              title={category.title}
              category={category.key}
              blogroll={blogroll}
              startOpen={activePath === category.key}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default BlogRoll
