import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import FormField from './FormField'
import useTranslations from '../../../hooks/useTranslations'

const Input = ({
  isDisabled,
  onBlur,
  onChange,
  type,
  textError,
  label,
  name,
  value,
  autoComplete,
  placeholder,
  hasError,
  size,
  literal,
  id,
  isRequired,
  hiddenLabel,
  literalLabel,
}) => {
  const classes = cx('input', {
    [size]: size,
  })

  const t = useTranslations()

  return (
    <FormField
      isDisabled={isDisabled}
      label={label}
      hasError={hasError}
      textError={textError}
      hiddenLabel={hiddenLabel}
      labelFor={id}
      isRequired={isRequired}
      literalLabel={literalLabel}
    >
      <input
        className={classes}
        type={type}
        id={id}
        onBlur={onBlur}
        onChange={onChange}
        name={name}
        value={value}
        autoComplete={autoComplete}
        placeholder={t(placeholder)}
      />
    </FormField>
  )
}

Input.propTypes = {
  isDisabled: FormField.propTypes.isDisabled,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['email', 'text', 'password', 'number']).isRequired,
  size: PropTypes.oneOf(['normal', 'narrow']),
  textError: FormField.propTypes.textError,
  label: FormField.propTypes.label,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
}

Input.defaultProps = {
  size: 'normal',
}

export default Input
